* {
	font-family: 'Open Sans', sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-text);
	fill: var(--color-text);
}

body {
	margin: 0px;
	height: 100vh;
	width: 100%;
	overflow: hidden;
}

button {
	border: none;
}

p {
	font-size: var(--font-size-medium);
	margin: 6px 0px;
}

/* Used for tab bar headings */
h3 {
	font-weight:  var(--font-weight-semibold);
	color: #DEDFDE;
	font-size: 16px;
}

/* Used for navigational headings */
h4 {
	text-transform: uppercase;
	color: #F4F4F4;
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	margin: 6px 0px;
}

/* Functional small headers  */
h6 {
	margin: 12px 0px;
	font-weight:  var(--font-weight-semibold);
	color:  var(--color-grey-high);
	font-size: 14px;
	text-transform: uppercase;
}

p, h1, h2, h3, h4, h5, h6 {
	color: var(--color-text);
}

input, textarea {
	border: none;
}

textarea {
	resize: none;
}

pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6em;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}

input:focus, textarea:focus {
	outline: none;
}

.weight-regular {
	font-weight: var(--font-weight-regular);
}
.weight-semibold {
	font-weight: var(--font-weight-semibold);
}

.no-wrap {
	white-space: nowrap;
}

.no-shrink {
	flex-shrink: 0;
}

.flex-container {
	display: flex;
}

.flex-container-centered {
	display:  flex;
	align-items: center;
}

.flex-container-centered-justified {
	display:  flex;
	align-items: center;
	justify-content: center;
}

.flex {
	flex: 1;
}

.flex-column {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.expand {
	width: 100%;
	height: 100%;
}

.height-100 {
	height: 100%;
}

.relative {
	position: relative;
}

.ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.auto-opacity {
	opacity: 1;
	transition: opacity 150ms;
	cursor:  pointer;
}

.auto-opacity:hover {
	opacity:  0.8;
}

.auto-opacity:held {
	opacity:  0.7;
}

.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.absolute-expand {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.regular {
	font-weight: var(--font-weight-regular);
}

.medium {
	font-weight: var(--font-weight-medium);
}

.semibold {
	font-weight: var(--font-weight-semibold);
}

.bold {
	font-weight: var(--font-weight-bold);
}

.font-size-tiny {
	font-size: var(--font-size-tiny);
}

.font-size-small {
	font-size: var(--font-size-small);
}

.font-size-medium {
	font-size: var(--font-size-medium);
}

.font-size-large {
	font-size: var(--font-size-large);
}

.margin-0 {
	margin: 0px;
}
.margin-6 {
	margin: 6px;
}
.margin-6-0 {
	margin: 6px 0px;
}
.margin-0-6 {
	margin: 0px 6px;
}
.margin-12 {
	margin: 12px;
}
.margin-12-0 {
	margin: 12px 0px;
}
.margin-0-12 {
	margin: 0px 12px;
}
.padding-6 {
	padding: 6px;
}
.padding-6-0 {
	padding: 6px 0px;
}
.padding-0-6 {
	padding: 0px 6px;
}
.padding-12 {
	padding: 12px;
}
.padding-12-0 {
	padding: 12px 0px;
}
.padding-0-12 {
	padding: 0px 12px;
}
.padding-24-0 {
	padding: 24px 0px;
}
.padding-0-24 {
	padding: 0px 24px;
}

.space-6 {
	width: 6px;
	height: 6px;
}
.space-12 {
	width: 12px;
	height: 12px;
}
.space-24 {
	width: 24px;
	height: 24px;
}

.white {
	color: white;
	fill: white;
}

.error-red {
	color: var(--color-error);
	fill: var(--color-error);
}

.nowrap {
	white-space: nowrap;
}

.pointer-events-none {
	pointer-events: none;
}

.debug-red {
	background-color: red;
}
